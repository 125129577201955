export default {
  model: {
    properties: {
      count: 1,
    },
    class: 'viewselector',
    entities: [
      {
        class: 'viewselector',
        properties: {
          name: 'view-options',
          options: [
            {
              value: 'show-internal-projects',
              selected: true,
            },
            {
              value: 'show-without-activity',
              selected: false,
            },
            {
              value: 'show-migrated-to-resource-planner',
              selected: true,
            },
          ],
        },
      },
    ],
    links: [
      {
        href: '/viewoptions',
        rel: 'self',
      },
    ],
  },
};

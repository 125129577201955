export default {
  Entities: [
    {
      Properties: {
        FilterCategory: 'General',
        FilterItems: [
          {
            Id: 5,
            Name: 'ProjectManager',
            ContentUrl: 'api/v2/filters/project-manager/content',
            ChildFilters: ['Project', 'ProjectCategory'],
            isInclude: true,
          },
          {
            Id: 10,
            Name: 'ContractType',
            ContentUrl: 'api/v2/filters/contract-type/content',
            ChildFilters: ['Project', 'ProjectCategory'],
            isInclude: true,
          },
          {
            Id: 12,
            Name: 'Employee',
            ContentUrl: 'api/v2/filters/employee/content',
            ChildFilters: ['Project', 'ApprovalManager', 'Customer'],
            ParentFilters: ['LegalEntity'],
            isInclude: true,
          },
        ],
      },
    },
    {
      Properties: {
        FilterCategory: 'Project',
        FilterItems: [
          {
            Id: 3,
            Name: 'Project',
            ContentUrl: 'api/v2/filters/project/content',
            ChildFilters: ['AccountManager', 'ApprovalManager'],
            ParentFilters: ['ProjectManager', 'ContractType'],
            isInclude: true,
          },
          {
            Id: 9,
            Name: 'ProjectCategory',
            ContentUrl: 'api/v2/filters/project-category/content',
            ChildFilters: ['Task', 'TaskType'],
            ParentFilters: ['ContractType', 'Project'],
            isInclude: true,
          },
          {
            Id: 48,
            Name: 'ProjectDepartment',
            ContentUrl: 'api/v2/filters/department/content',
            ChildFilters: ['ProjectCategory', 'Project'],
            ParentFilters: ['DepartmentEmployee', 'EmployeeType'],
            isInclude: true,
          },
        ],
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/filters/list/DesignSevenExampleReport',
      Rel: 'self',
    },
  ],
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringToPascal } from 'src/utils/string';
import { translationAnyText } from 'src/utils/translation';
import { MenuItem, Stack, FormControl, InputLabel } from 'src/components/mui-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button, ListItem, ListItemButton, ListItemText, Menu, Switch } from '@mui/material';
import {
  IViewOptionsField,
  ViewOptionsChangeParameters,
  IViewOptionsSelectField,
} from '../../../../types';

interface ResourceViewOptionsProps {
  viewOptionsLeftFilter?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
  viewOptionsSelectFields?: Array<IViewOptionsSelectField>;
}

export const ViewOptions = ({
  viewOptionsLeftFilter,
  viewOptionsFields,
  viewOptionsChange,
  viewOptionsSelectFields,
}: ResourceViewOptionsProps) => {
  const { t } = useTranslation('viewOptions');
  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    viewOptionsChange?.([{ name, value }]);
  };

  const [selectedViewOptions, setSelectedViewOptions] = useState<string[]>(
    viewOptionsSelectFields?.[0]?.value || [],
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const viewOptionOnToggle = (value: any) => {
    setSelectedViewOptions((prev) => {
      const currentIndex = prev.indexOf(value);
      const newChecked = [...prev];
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      return newChecked;
    });
  };

  const optionIsSelected = (value: any) => selectedViewOptions.indexOf(value) !== -1;

  return (
    <Stack
      direction="row"
      justifyContent={viewOptionsLeftFilter ? 'space-between' : 'flex-end'}
      alignItems="bottom"
    >
      {viewOptionsLeftFilter && viewOptionsLeftFilter}

      {viewOptionsFields && viewOptionsFields.length > 0 && (
        <Stack direction="row">
          {viewOptionsFields?.map(({ name: fieldName, value: fieldValue, options }) => (
            <FormControl key={fieldName} size="small">
              <InputLabel id={`${fieldName}-label`}>
                {translationAnyText(t, `FieldOption${stringToPascal(fieldName)}Label`)}
              </InputLabel>
              <Select
                labelId={`${fieldName}-label`}
                name={fieldName}
                label={translationAnyText(t, `FieldOption${stringToPascal(fieldName)}Label`)}
                value={fieldValue}
                onChange={(e) => {
                  handleSelectChange(e);
                }}
                data-automation-id={`ViewOptions${stringToPascal(fieldName)}`}
              >
                {options.map(({ value }) => {
                  const label = translationAnyText(t, `FieldOption${stringToPascal(value)}`);

                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ))}
        </Stack>
      )}

      {viewOptionsSelectFields && viewOptionsSelectFields.length > 0 && (
        <Stack direction="row">
          {viewOptionsSelectFields?.map(({ name: fieldName, value: fieldValue, options }) => (
            <>
              <Button
                id="ViewOptionsButton"
                aria-controls="ViewOptionsMenu"
                aria-haspopup="true"
                variant="outlined"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
                size="small"
                sx={{
                  textTransform: 'capitalize',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                {t('FieldOptionViewOptionsText')}
              </Button>
              <Menu
                id="ViewOptionsMenu"
                MenuListProps={{
                  'aria-labelledby': 'ViewOptionsButton',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                slotProps={{
                  paper: { sx: { maxWidth: 320 } },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {options.map(({ value }) => {
                  const label = translationAnyText(t, `FieldOption${stringToPascal(value)}`);
                  return (
                    <ListItem sx={{ padding: 0 }}>
                      <ListItemButton onClick={() => viewOptionOnToggle(value)}>
                        <Switch
                          edge="start"
                          checked={optionIsSelected(value)}
                          inputProps={{
                            'aria-labelledby': value,
                          }}
                        />
                        <ListItemText id={value} primary={label} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Menu>
            </>
          ))}
        </Stack>
      )}
    </Stack>
  );
};

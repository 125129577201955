/* eslint-disable no-alert */
import { useEffect, useMemo, useRef, useState } from 'react';
import { DataGrid } from 'src/components/mui-components/DataGrid';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnGroupingModel,
  GridRenderCellParams,
  GridRowParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useFilterStore } from 'src/stores/FilterStore';
import { IconButton, Typography } from 'src/components/mui-components';
import { toggleFilterContainerEventName } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterContainer';
import { useTranslation } from 'react-i18next';
import { useGetSearchProjects } from 'src/apis/searchProjectsAPI/get/searchProjects';
import { Box, LinearProgress, Tooltip } from '@mui/material';
import {
  Delete,
  EditNote,
  Edit,
  RequestQuote,
  RuleFolder,
  PlayArrow,
  Pause,
  Done,
  Close,
  InfoOutlined,
} from '@mui/icons-material';
import { searchProjectsSelectedFilterListStateKey } from '../../localStorageKeys';
import { Toolbar } from './Toolbar';

import styles from '../../SearchProjects.module.scss';
import { TimeRegistrationsDialog } from '../TimeRegistrationsDialog';
import { BookingActionDialog } from '../BookingActionDialog';

interface ISearchProject {
  selectedViewOptions: any;
  // {
  //   [key: string]: string;
  // };
}

const slots = (isRowSelected: boolean, columns: GridColDef[], totalRows: number) => ({
  toolbar: () => <Toolbar isRowSelected={isRowSelected} columns={columns} totalRows={totalRows} />,
});

const valueOptions = [
  { label: 'Quotation', value: 'quotation', icon: RequestQuote },
  { label: 'Approved', value: 'approved', icon: RequestQuote },
  { label: 'In progress', value: 'inProgress', icon: PlayArrow },
  { label: 'On hold', value: 'onHold', icon: Pause },
  { label: 'Completed', value: 'completed', icon: Done },
  { label: 'Archived', value: 'archived', icon: RuleFolder },
  { label: 'Cancelled', value: 'cancelled', icon: Close },
];

const getColor = (value: number) => {
  if (value === 100) {
    return styles.progressInProgress;
  }
  if (value < 81) {
    return styles.progressPositive;
  }
  if (value < 100) {
    return styles.progressWarning;
  }
  return styles.progressNegative;
};

export const Table = ({ selectedViewOptions }: ISearchProject) => {
  const { t } = useTranslation('searchProjects');
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(searchProjectsSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

  const {
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    children: rows,
  } = useGetSearchProjects({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  const [isRowSelected, setIsRowSelected] = useState(false);

  const handleRowSelectionChange = (newSelection: any) => {
    setIsRowSelected(newSelection.length > 0);
  };

  // Time Registration Dialog
  const [showTimeRegistration, setShowTimeRegistration] = useState(false);

  // BookKeeping Dialog
  const [showBookingAction, setShowBookingAction] = useState(false);

  // const rows: IRevenueForecastItem[] = [...children];

  const columns: GridColDef[] = [
    {
      field: 'no',
      headerName: t('TableHeaderProjectNo'),
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => (
        // Put correct link here
        <a className={styles.link} href={params.row.link}>
          {params.value}
        </a>
      ),
      colSpan: (row: any) => {
        if (row.id === 'total') {
          return 3;
        }
        return undefined;
      },
      valueGetter: (params: GridValueGetterParams): any => {
        if (params.row.id === 'total') {
          return params.row.name;
        }
        return params.value;
      },
    },
    {
      field: 'name',
      headerName: t('TableHeaderProjectName'),
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => (
        // Put correct link here
        <a className={styles.link} href={params.row.link}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'completion',
      headerName: t('TableHeaderCompletionMeter'),
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        if (params.id === 'total') {
          return '';
        }
        const value = parseInt(params.value as string, 10);
        return (
          value >= 0 && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LinearProgress
                className={getColor(value)}
                variant="determinate"
                value={Math.min(value, 100)}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '58%',
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              />
              <Typography sx={{ position: 'absolute', color: 'white' }}>{`${value}%`}</Typography>
            </Box>
          )
        );
      },
    },
    {
      field: 'customer',
      headerName: t('TableHeaderCustomer'),
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        // Put correct link here
        <a className={styles.link} href={params.row.link}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'pm',
      headerName: t('TableHeaderPM'),
      minWidth: 100,
    },
    // 'am' only in Show all columns
    {
      field: 'am',
      headerName: t('TableHeaderAM'),
      minWidth: 100,
    },
    {
      field: 'type',
      headerName: t('TableHeaderProjectType'),
      minWidth: 130,
    },
    // 'category' only in Show all columns
    {
      field: 'category',
      headerName: t('TableHeaderProjectCategory'),
      minWidth: 130,
    },
    {
      field: 'stage',
      headerName: t('TableHeaderProjectStage'),
      minWidth: 130,
    },
    // Details of budget show in Show all columns (Project, Tasks, Alloc.)
    // Title will be t('TableHeaderBudget')
    {
      field: 'budgetProject',
      headerName: t('TableHeaderProjectBudgetHours'), // t('TableHeaderBudgetHours') if disable "Show all columns"
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'budgetTask',
      headerName: t('TableHeaderTaskBudgetHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'budgetAlloc',
      headerName: t('TableHeaderAllocatedBudgetHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'actual',
      headerName: t('TableHeaderActualHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            className={styles.cornerInfo}
            title="Show time registrations for this subject"
            onClick={() => setShowTimeRegistration(true)}
          />
          <Typography>{params.value}</Typography>
        </Box>
      ),
    },
    {
      field: 'action',
      headerName: '',
      type: 'actions',
      minWidth: 130,
      maxWidth: 130,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams) => {
        if (params.id === 'total') {
          return [];
        }
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('EditProjectText')}>
                <EditNote />
              </Tooltip>
            }
            label={t('EditProjectText')}
            onClick={() => alert('edit')}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t('EditProjectSettingsText')}>
                <Edit />
              </Tooltip>
            }
            label={t('EditProjectSettingsText')}
            onClick={() => alert('edit projects')}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t('DeleteProjectText')}>
                <Delete />
              </Tooltip>
            }
            label={t('DeleteProjectText')}
            onClick={() => alert('delete')}
          />,
        ];
      },
    },
    {
      field: 'status',
      headerName: '',
      type: 'singleSelect',
      valueOptions: valueOptions.map((option) => option.value),
      minWidth: 130,
      maxWidth: 130,
      editable: true,
      // cellClassName: (params: any) => {
      //   const className = `status${params.value}`;
      //   return className in styles ? (styles as Record<string, string>)[className] : '';
      // },
      renderCell: (params: GridRenderCellParams) => {
        const statusOption = valueOptions.find((option) => option.value === params.value);
        const Icon = statusOption ? statusOption.icon : null;
        return (
          <Box display="flex" alignItems="center">
            {Icon ? <Icon /> : null}
            <Typography sx={{ ml: 1 }}>{statusOption?.label}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'bookingInfo',
      headerName: '',
      minWidth: 55,
      maxWidth: 55,
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          // eslint-disable-next-line no-alert
          <IconButton title={t('PendingBookingInfo')} onClick={() => setShowBookingAction(true)}>
            <InfoOutlined fontSize="small" />
          </IconButton>
        ) : null,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'budget',
      headerName: t('TableHeaderBudget'),
      headerAlign: 'center',
      children: [{ field: 'budgetProject' }, { field: 'budgetTask' }, { field: 'budgetAlloc' }],
    },
  ];

  const pinnedRows = useMemo(
    () => ({
      bottom: [
        {
          id: 'total',
          name: 'Total',
          budgetProject: '10,600',
          budgetTask: '20,500',
          budgetAlloc: '40,500',
          actual: '50,100',
          // ...totalRow,
        },
      ],
    }),
    [],
  );
  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  const listener = (ref: HTMLDivElement | null) => {
    if (ref) {
      setTimeout(() => {
        setOffSetTop(ref.offsetTop);
      }, 1000);
    }
  };

  // Set on FilterContainer toggle
  useEffect(() => {
    const ref = dataGridContainerRef.current;
    window.addEventListener(toggleFilterContainerEventName, () => listener(ref));

    return () => {
      window.removeEventListener(toggleFilterContainerEventName, () => listener(ref));
    };
  }, [dataGridContainerRef]);

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={isEmpty} isError={isError}>
      <div
        style={{ width: '100%', height: `calc(100vh - ${offSetTop + 20}px)` }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          experimentalFeatures={{ columnGrouping: true }}
          columns={columns}
          rows={rows}
          disableColumnMenu
          // disableColumnResize
          hideFooter
          checkboxSelection
          disableRowSelectionOnClick
          disableColumnTopBorder
          pinnedRows={pinnedRows}
          onRowSelectionModelChange={(newSelection) => handleRowSelectionChange(newSelection)}
          autosizeOnMount
          autosizeOptions={{ includeOutliers: true, includeHeaders: true, expand: true }}
          // autosizeOptions={{ columns: ['name'], expand: true }}
          slots={slots(isRowSelected, columns, rows.length)}
          sx={{
            // Allow long text to wrap in the header
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 'normal',
            },
            '.MuiDataGrid-columnHeader--filledGroup': {
              '& .MuiDataGrid-columnHeaderTitleContainer': {
                borderBottomWidth: 1,
              },
            },
          }}
          columnGroupingModel={columnGroupingModel}
          data-automation-id="SearchProjectsTable"
        />
        {/* Action Dialog */}
        <TimeRegistrationsDialog
          showTimeRegistration={showTimeRegistration}
          setShowTimeRegistration={setShowTimeRegistration}
        />

        <BookingActionDialog
          showBookingAction={showBookingAction}
          setShowBookingAction={setShowBookingAction}
        />
      </div>
    </ResponseHandler>
  );
};

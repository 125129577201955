/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { IFilterProps, IMenuItem, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
// import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { safeParseJson } from 'src/utils/object';
import { ISavedView } from 'src/apis/resourcePlannerAPI/savedViewAPI';
import { v4 as uuidv4 } from 'uuid';

import {
  IViewOptionsSelectField,
  ViewOptionsChangeParameters,
} from 'src/components/layout/FilterLayout/types';
import { IconButton, ToastifyAlert } from 'src/components/mui-components';
import { Delete, Edit, ContentCopyRounded, InfoOutlined, Search } from '@mui/icons-material';
import { Stack, SelectChangeEvent, TextField, InputAdornment, Box } from '@mui/material';
// import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { NavigationBlocker } from 'src/components/utils/NavigationBlocker';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { useGetFirstName, useGetUserId } from 'src/apis/usersAPI/meAPI';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import { useGetSearchProjectsViewOptions } from 'src/apis/searchProjectsAPI';
import { INITIAL_SAVED_VIEW, SNACKBAR_CONFIG } from './constants';
import {
  searchProjectsSelectedFilterListStateKey,
  SPSelectedFilterListStateKey,
  GetSPSavedViewsKey,
  GetSPReturningUserKey,
  SPViewOptionsStateKey,
} from './localStorageKeys';

import { sortViews } from '../ResourcePlanner/helper/sortViews';
import {
  ISavedViewForm,
  SnackbarMode,
  TTextDialogMode,
  TFormDialogMode,
  TSnackbarKeys,
  TViewOptions,
} from './types/searchProjects';
import { ViewFormDialog, ViewTextDialog } from './components/SavedViewDialog';
import { Table } from './components/Table';

export const SearchProjects = () => {
  // For mock purpose
  // const pageIdentifier = useGetCurrentPageIdentifier();
  const pageIdentifier = 'SearchProjects';
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);
  const qc = useQueryClient();

  const { fields: initialFields } = useGetSearchProjectsViewOptions();

  // Save View
  const { t } = useTranslation('savedView');
  const { t: tSearchProjects } = useTranslation('searchProjects');

  const { shortNameWithoutUrl } = useGetShortNameWithoutUrl();
  const localStorageReturningUser =
    localStorage.getItem(GetSPReturningUserKey(shortNameWithoutUrl)) ?? '';
  const returningUser = localStorageReturningUser === 'true';
  const localStorageSavedViews =
    localStorage.getItem(GetSPSavedViewsKey(shortNameWithoutUrl)) ?? '';
  const [savedViews, setSavedViews] = useState<ISavedView[]>([]);

  // For non-returning user
  const flattenedFilterItemsList = filterList.reduce(
    (a, { filterItems }) => [...a, ...(filterItems ?? [])],
    [] as IFilterItemProperties[],
  );
  const employeeFilter = flattenedFilterItemsList.find(({ name }) => name === 'Employee');
  const { firstName } = useGetFirstName();
  const { dropdownList } = useGetDropdownWithSearchAPI({
    key: `filterInput${employeeFilter?.id}`,
    MSWKey: `FILTER_INPUT_${employeeFilter?.id}`,
    path: `/${employeeFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: firstName,
    enabled: !!employeeFilter?.contentUrl && !returningUser,
  });
  const { userId } = useGetUserId();
  const currentUserOption = dropdownList?.find((item) => item.value === String(userId));
  // For non-returning user

  const sortedViews = useMemo((): ISavedView[] => sortViews(savedViews), [savedViews]);
  const [activeView, setActiveView] = useState<string>();
  const [destinationView, setDestinationView] = useState<string>();

  const savedViewOptions = sortedViews?.find((v) => v.id === activeView)?.viewOptions;
  const [changedViewOptions, setChangedViewOptions] = useState<TViewOptions | undefined>(
    savedViewOptions ?? undefined,
  );
  // End save

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(searchProjectsSelectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  const onViewOptionsChange = (optionItems: ViewOptionsChangeParameters[]) => {
    // eslint-disable-next-line no-alert
    alert('view change');
  };

  // Save View
  const initialFilter = useMemo(() => {
    if (!currentUserOption || !employeeFilter) {
      return undefined;
    }

    return {
      Employee: {
        label: employeeFilter.name,
        values: [
          {
            label: currentUserOption.label,
            value: currentUserOption.value,
          },
        ],
        contentUrl: employeeFilter.contentUrl,
        childFilters: employeeFilter.childFilters,
        parentFilters: employeeFilter.parentFilters,
        isInclude: true,
      },
    };
  }, [currentUserOption, employeeFilter]);

  const displayToast = (type: SnackbarMode) => {
    const snackBarConfig = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = snackBarConfig ?? {};
    toast.success(
      <ToastifyAlert
        title={t(title as TSnackbarKeys)}
        description={t(description as TSnackbarKeys)}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    isDefault: z.boolean(),
  });

  const updateSavedViews = useCallback(
    (sv: ISavedView[]) => {
      setSavedViews(() => {
        localStorage.setItem(GetSPSavedViewsKey(shortNameWithoutUrl), JSON.stringify(sv));
        return sv;
      });
    },
    [shortNameWithoutUrl],
  );

  const [showFormDialog, setShowFormDialog] = useState<TFormDialogMode | ''>('');
  const [showTextDialog, setShowTextDialog] = useState<TTextDialogMode | ''>('');
  const [isBlock, setIsBlock] = useState(false);

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const updateActiveView = (viewId?: string) => {
    setActiveView(() => viewId);
  };

  const onSubmit = (data: ISavedViewForm) => {
    // set default to false for all views
    const viewData: ISavedView[] = savedViews.map((view) => ({
      ...view,
      isDefault: data.isDefault ? false : view.isDefault,
    }));

    const filters = localStorage.getItem(SPSelectedFilterListStateKey);
    const newID = uuidv4();
    let updatedData: ISavedView[];

    switch (showFormDialog) {
      case 'save':
        if (!filters) {
          return;
        }

        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: safeParseJson(filters),
          },
        ];
        displayToast('save');
        updateActiveView(newID);
        break;

      case 'edit':
        updatedData = viewData.map((view) => {
          if (view.id === activeView) {
            return {
              ...view,
              title: data.title,
              isDefault: data.isDefault,
            };
          }
          return view;
        });
        displayToast('edit');
        break;

      default:
        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: filters ? safeParseJson(filters) : {},
          },
        ];
        displayToast('duplicate');
        updateActiveView(newID);
        break;
    }

    updateSavedViews(updatedData);
    form.reset();
  };

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      icon: <Edit />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('edit');
        }
      },
    },
    {
      id: 'duplicate',
      label: t('DuplicateViewMenuText'),
      icon: <ContentCopyRounded />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('duplicate');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      icon: <Delete />,
      action: () => {
        setShowTextDialog('delete');
      },
    },
  ];

  const onDelete = () => {
    setShowTextDialog('');
    const updatedData = savedViews.filter((view) => view.id !== activeView);
    updateSavedViews(updatedData);
    updateActiveView(sortViews(updatedData)[0]?.id ?? '');
    displayToast('delete');
  };

  const blockerOnCancel = () => {
    if (!destinationView || destinationView === activeView) {
      return;
    }
    setDestinationView((prev) => {
      setActiveView(prev);
      return undefined;
    });
  };

  const onChangesUpdate = () => {
    const updatedSaveView = savedViews.map((v) => {
      const view = { ...v };
      if (view.id === activeView) {
        view.filters = safeParseJson(localStorage.getItem(SPSelectedFilterListStateKey) ?? '');
      }
      return view;
    });

    updateSavedViews(updatedSaveView);
    setShowTextDialog('');
    displayToast('changes');

    if (destinationView && destinationView !== activeView) {
      updateActiveView(destinationView);
    }
  };
  const handleViewAction = (view: string) => {
    if (view === 'save') {
      form.reset();
      setShowFormDialog('save');
    } else if (view === 'changesDialog') {
      setShowTextDialog('changes');
    } else {
      onChangesUpdate();
    }
  };

  useEffect(() => {
    if (!localStorageSavedViews) {
      return;
    }
    setSavedViews(safeParseJson(localStorageSavedViews));
  }, [localStorageSavedViews]);

  useEffect(() => {
    if (activeView || !sortedViews?.length) {
      return;
    }
    updateActiveView(sortedViews?.[0]?.id);
  }, [activeView, sortedViews]);

  useEffect(() => {
    if (returningUser || savedViews.length || !initialFilter) {
      return;
    }
    localStorage.setItem(GetSPReturningUserKey(shortNameWithoutUrl), 'true');
    const id = uuidv4();
    updateSavedViews([
      {
        ...INITIAL_SAVED_VIEW,
        id,
        title: t('InitialViewTitle'),
        filters: initialFilter,
      },
    ]);
  }, [initialFilter, returningUser, savedViews.length, shortNameWithoutUrl, t, updateSavedViews]);

  useEffect(() => {
    if (!savedViewOptions) {
      return;
    }
    setChangedViewOptions(savedViewOptions);
  }, [savedViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(SPViewOptionsStateKey, JSON.stringify(changedViewOptions));
    // setRpLoading(false);
  }, [changedViewOptions]);

  // #region END Save View

  const fields: Array<IViewOptionsSelectField> = useMemo(
    () =>
      initialFields.map((field: IViewOptionsSelectField) => ({
        ...field,
        value: field.value,
        options: field.options.map((option) => ({
          ...option,
        })),
      })),
    [initialFields],
  );

  return (
    <ResponseHandler isLoading={isLoading} isError={isError}>
      <FilterLayout
        filterList={filterList}
        selectedFilterList={getSelectedFilterFromLocalStorage()}
        viewOptionsSelectFields={fields}
        viewOptionsChange={onViewOptionsChange}
        viewOptionsLeftFilter={
          <Box sx={{ width: 350 }}>
            <TextField
              id="outlined-basic"
              // label="Full text search"
              variant="outlined"
              size="small"
              fullWidth
              // Need trsnalation
              placeholder="Search Projects, customer, description, etc."
              // placeholder={tSearchProjects('FullTextSearchText')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              // onBlur={(e) => onViewOptionsChange(e.target.value)}
              // eslint-disable-next-line no-alert
              onBlur={() => alert('Value changed')}
            />
          </Box>
        }
        localStorageNamePrefix="search-projects"
        allowSavedViews
        savedViewsList={sortedViews}
        activeView={activeView}
        menuItems={menuItems}
        handleViewAction={handleViewAction}
        setActiveView={updateActiveView}
        setDestinationView={setDestinationView}
        setIsBlock={setIsBlock}
      >
        <Table selectedViewOptions={changedViewOptions} />

        {/* Save view dialog */}
        {/* For form dialog */}
        {showFormDialog && (
          <ViewFormDialog
            showFormDialog={showFormDialog}
            setShowFormDialog={setShowFormDialog}
            onSubmit={onSubmit}
            form={form}
          />
        )}
        {/* For text alert dialog */}
        {showTextDialog && (
          <ViewTextDialog
            showTextDialog={showTextDialog}
            setShowTextDialog={setShowTextDialog}
            onCancel={blockerOnCancel}
            onDelete={onDelete}
            onChangesUpdate={onChangesUpdate}
          />
        )}
        <NavigationBlocker
          isBlock={isBlock}
          setIsBlock={setIsBlock}
          title={t('ChangesViewModalTitle')}
          description={t('ChangesViewModalDesc')}
          onAction={onChangesUpdate}
          okText={t('DialogYesText')}
          cancelText={t('DialogNoText')}
        />
      </FilterLayout>
    </ResponseHandler>
  );
};

const array = Array(50);

function getRandomArbitrary(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

function generateRows() {
  const rows = [];
  for (let index = 1; index <= array.length; index += 1) {
    rows.push({
      bookingInfo: Math.random() < 0.2,
      id: index,
      no: `${Math.floor(Math.random() * 100000)}-${Math.floor(Math.random() * 100)}`,
      name: `Project ${index}`,
      completion: `${Math.floor(Math.random() * 150)}%`,
      customer: `Customer ${index}`,
      pm: `PM ${index}`,
      am: `AM ${index}`,
      type: `${Math.floor(Math.random() * 20) + 10}. Type ${index}`,
      category: `Category ${index}`,
      stage: `Stage ${index}`,
      budgetProject: getRandomArbitrary(0, 20000),
      budgetTask: getRandomArbitrary(0, 20000),
      budgetAlloc: getRandomArbitrary(0, 20000),
      actual: getRandomArbitrary(0, 20000),
      status: [
        'approved',
        'quotation',
        'completed',
        'inProgress',
        'onHold',
        'archived',
        'cancelled',
      ][Math.floor(Math.random() * 7)],
    });
  }
  return rows;
}

export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10003',
      responseType: 'SearchProjects',
      viewOptions: {
        options: ['show-internal-projects', 'show-no-activity'],
      },
      children: generateRows(),
    },
    links: [{ href: '/search-projects', rel: 'self' }],
  },
  DeterministicHash: 2699400,
  StatusCode: 200,
};

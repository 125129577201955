import { ISavedView } from 'src/apis/resourcePlannerAPI/savedViewAPI';

export const RPProjectViewDisableExpandAllCount = 25;

export const RPEmployeeViewDisableExpandAllCount = RPProjectViewDisableExpandAllCount;

export const RPShimmerRowCount = 1;

export const SNACKBAR_CONFIG = [
  {
    type: 'save',
    title: 'SaveViewSuccessToastTitle',
    description: 'SaveViewSuccessToastBody',
  },
  {
    type: 'edit',
    title: 'UpdateViewSuccessToastTitle',
    description: 'UpdateViewSuccessToastBody',
  },
  {
    type: 'delete',
    title: 'DeleteViewSuccessToastTitle',
    description: 'DeleteViewSuccessToastBody',
  },
  {
    type: 'changes',
    title: 'ChangesViewSuccessToastTitle',
    description: 'ChangesViewSuccessToastBody',
  },
  {
    type: 'duplicate',
    title: 'DuplicateViewSuccessToastTitle',
    description: 'DuplicateViewSuccessToastBody',
  },
];

export const INITIAL_SAVED_VIEW: ISavedView = {
  title: '',
  isDefault: false,
  createdDate: new Date(),
  filters: {},
  viewOptions: {
    'grouped-by': 'group-by-resource',
    'included-work-items': 'all-work-items',
    'reporting-types': 'availability',
    'period-types': 'week',
    'unit-types': 'hours',
  },
};

import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import getViewOptions from 'src/mocks/searchProjects/getSearchProjectsViewOptions';
import { ISearchProjectsModelResult } from '../types';

const SEARCH_PROJECTS_VIEW_OPTIONS_URL_PATH = '/api/v2/search-projects/view-optionss';

export const SEARCH_PROJECTS_VIEW_OPTIONS_MSW_STATUS_KEY = 'GetSearchProjectViewOptionsAPI';

export const SEARCH_PROJECTS_VIEW_OPTIONS_KEY = 'SEARCH_PROJECTS_VIEW_OPTIONS';

export interface IMappedViewOptions {
  name: string;
  value: string[] | undefined;
  options: Array<{
    value: string;
  }>;
}

const viewOptionsFetch = (): Promise<ISearchProjectsModelResult> =>
  getFetch({
    path: SEARCH_PROJECTS_VIEW_OPTIONS_URL_PATH,
    key: SEARCH_PROJECTS_VIEW_OPTIONS_MSW_STATUS_KEY,
  });

export const useGetSearchProjectsViewOptions = () => {
  const {
    data: { model: { entities = [] } = {} } = {},
    isError,
    ...restProps
  } = useQuery([SEARCH_PROJECTS_VIEW_OPTIONS_KEY], viewOptionsFetch, { retry: 0 });

  // Mock data, to be remove
  if (isError) {
    const mockData = getViewOptions.model.entities;

    return {
      fields: mockData.map(({ properties: { name = '', options = [] } = {} }) => ({
        name,
        value: options.filter((option) => option.selected).map((option) => option.value),
        options: options.map(({ value }) => ({
          value,
        })),
      })) as IMappedViewOptions[],
      ...restProps,
    };
  }

  return {
    fields: entities.map(({ properties: { name = '', options = [] } = {} }) => ({
      name,
      value: options.filter((option) => option.selected).map((option) => option.value),
      options: options.map(({ value }) => ({
        value,
      })),
    })) as IMappedViewOptions[],
    ...restProps,
  };
};

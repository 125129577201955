export const searchProjectsSelectedFilterListStateKey = 'search-projects-selected-filter-list';
export const searchProjectsViewOptionsStateKey = 'search-projects-view-options';

export const GetSPKey = (key: string, user: string = '') => `search-projects-${user}-${key}`;

export const SPViewOptionsStateKey = 'search-projects-view-options';

export const SPSelectedFilterListStateKey = 'search-projects-selected-filter-list';

export const SPinvalidateOldQueryOnViewOptionChange = 'search-projects-invalidate-old-query';

export const GetSPSavedViewsKey = (user?: string) => GetSPKey('saved-views', user);

export const GetSPReturningUserKey = (user?: string) => GetSPKey('returning-user', user);
